import { Col, Form, InputGroup, Row, Button } from "react-bootstrap";

export default function Search({ onSearch, disabled, search, setSearch }) {
	function handleKeyPress(e) {
		var key = e.charCode || e.keyCode || 0; 

		if (key === 13) {
			e.preventDefault();
			onSearch();
		}
	}

	return 	<Row className="p-3">
				<Col xs={6}>
					<InputGroup>
						<Form.Control 
							value={ search } 
							onChange={ e => setSearch(e.target.value) } 
							placeholder="Search..." 
							onKeyDown={ handleKeyPress }
						/>
						<Button 
							disabled={ disabled }
							onClick={ onSearch }
						>
							Apply
						</Button>
					</InputGroup>
				</Col>
			</Row>
}