import { faBoxes } from "@fortawesome/free-solid-svg-icons";
import { useSessionStore } from "../../../Stores/SessionStore";
import Panel from "../../Common/Panel";
import { Col, OverlayTrigger, Row, Table, Tooltip } from "react-bootstrap";
import { useEffect, useState } from "react";
import { DateTime } from "luxon";
import Pagination from "../../Common/Pagination";
import Spinner from "../../Common/Spinner";
import Page from "../../Common/Page";
import Search from "../../Common/Search";
import currency from "currency.js";

export default function OrdersIndex() {
	const store = useSessionStore();
	
	const [loading, setLoading] = useState(false);
	const [search, setSearch] = useState("");
	const [orders, setOrders] = useState(null);
	const [mrcTotal, setMRCTotal] = useState(null);
	const [pagination, setPagination] = useState({
		PageIndex: 0,
		PageCount: 25,
		Total: 0
	});

	async function getOrders(pageIndex, pageCount) {
		setLoading(true);
		const results = await store.ChannelPartnerService.GetOrders(search, pageIndex, pageCount);
		
		if(results && results.Success) {
			setOrders(results.Data);
			setMRCTotal(results.SumTotal);
			setPagination({
				PageCount: pageCount,
				PageIndex: pageIndex,
				Total: results.Total
			});
			setLoading(false);
		}
	}

	useEffect(() => {
		getOrders(0, 25);
	}, []);

	function handlePageChanged(pageIndex, pageCount) {
		getOrders(pageIndex, pageCount);
	}

	return <Page
				icon={ faBoxes } 
				title="Orders"
			>
				<Panel title="Viewing Active Orders">
					<Search 
						disabled={ loading }
						onSearch={ () => getOrders(0, pagination.PageCount) }
						search={ search }
						setSearch={ setSearch }
					/>
					<Row>
						<Col>
							<Table className="mb-0">
								<thead>
									<tr>
										<th>Client</th>
										<th>ID</th>
										<th>Service</th>
										<th>Status</th>
										<th>Site Name</th>
										<th className="text-center">MRC</th>
										<th>Ordered Date</th>
									</tr>
								</thead>
								<tbody>
									{
										!loading && orders && orders.map(o => {
											return 	<tr>
														<td>{ o.Client }</td>
														<td>TC-{ o.ClientServiceID }</td>
														<td>{ o.Service }</td>
														<td>
															{
																o.Comment &&
																<OverlayTrigger overlay={ <Tooltip id={o.OpportunityID}>{ o.Comment ?? "" }</Tooltip>}>
																	<span className="text-primary" style={{ cursor: "pointer" }}>
																		{ o.Status }
																	</span>
																</OverlayTrigger>
															}
															{
																!o.Comment &&
																<span className="text-primary" style={{ cursor: "pointer" }}>
																	{ o.Status }
																</span>
															}
														</td>
														<td>{ o.SiteName }</td>
														<td className="text-center">
															{ currency(o.MRC, { separator: " ", symbol: "R" }).format() }
														</td>
														<td>{ DateTime.fromISO(o.OrderDate).toFormat("dd MMMM yyyy") }</td>
													</tr>
										})
									}
									{
										!loading && mrcTotal !== null &&
										<tr style={{ borderTopWidth: "3px" }}>
											<td colSpan="5" className="text-end">
												MRC Total
											</td>
											<td className="text-center">
												<b>{ currency(mrcTotal, { separator: " ", symbol: "R" }).format() }</b>
											</td>
											<td></td>
										</tr>
									}
								</tbody>
							</Table>
							{
								!loading &&
								<Pagination 
									filter={ pagination } 
									onPageChanged={ handlePageChanged } 
									totalResults={ orders ? orders.length : 0 }
								/>
							}
							<Spinner show={ loading } />
						</Col>
					</Row>
				</Panel>
			</Page>
}