import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import AvailableBandwidthModal from "./AvailableBandwidthModal";
import UsageStatisticsModal from "./UsageStatisticsModal";
import LogSupportTicketModal from "./LogSupportTicketModal";
import DocumentsModal from "./DocumentsModal";

export default function ServiceActions({ service }) {
	const [showLogSupportTicketModal, setShowLogSupportTicketModal] = useState(false);
	const [showAvailableDataModal, setShowAvailableDataModal] = useState(false);
	const [showUsageModal, setShowUsageModal] = useState(false);
	const [showDocumentsModal, setShowDocumentsModal] = useState(false);

	const toggle = React.forwardRef(({ children, onClick }, ref) => (
		<span
			ref={ref}
			style={{ cursor: "pointer" }}
			onClick={(e) => {
				e.preventDefault();
				onClick(e);
			}}
		>
			{children}
		</span>
	));

	return 	<>
				<Dropdown>
					<Dropdown.Toggle as={ toggle }>
						<FontAwesomeIcon icon={ faBars } />
					</Dropdown.Toggle>
					<Dropdown.Menu>
						{
							service.ServiceTypeID === 33 &&
							<Dropdown.Item onClick={ () => setShowAvailableDataModal(true) }>View Available Data</Dropdown.Item>
						}
						{
							service.RadiusUsername &&
							<Dropdown.Item onClick={ () => setShowUsageModal(true) }>View Usage Statistics</Dropdown.Item>
						}
						<Dropdown.Item onClick={ () => setShowLogSupportTicketModal(true) }>Log Support Ticket</Dropdown.Item>
						<Dropdown.Item 
							disabled={ service.TotalDocuments === 0 } 
							onClick={ () => setShowDocumentsModal(true) }>Documents ({service.TotalDocuments})</Dropdown.Item>
					</Dropdown.Menu>
				</Dropdown>
				<AvailableBandwidthModal 
					show={ showAvailableDataModal }
					clientServiceId={ service.ClientServiceID }
					onClose={ () => setShowAvailableDataModal(false) }
				/>
				<UsageStatisticsModal
					show={ showUsageModal }
					clientServiceId={ service.ClientServiceID }
					onClose={ () => setShowUsageModal(false) }
				/>
				<LogSupportTicketModal 
					show={ showLogSupportTicketModal }
					clientServiceId={ service.ClientServiceID }
					onClose={ () => setShowLogSupportTicketModal(false) }
				/>
				<DocumentsModal 
					show={ showDocumentsModal }
					clientServiceId={ service.ClientServiceID }
					onClose={ () => setShowDocumentsModal(false) }
				/>
			</>
			
}