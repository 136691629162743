import { faWrench } from "@fortawesome/free-solid-svg-icons";
import { useSessionStore } from "../../../Stores/SessionStore";
import Panel from "../../Common/Panel";
import { Badge, Col, Form, Row, Table } from "react-bootstrap";
import { useEffect, useState } from "react";
import { DateTime } from "luxon";
import Pagination from "../../Common/Pagination";
import Spinner from "../../Common/Spinner";
import Page from "../../Common/Page";
import Search from "../../Common/Search";
import ServiceActions from "./ServiceActions";
import currency from "currency.js";

export default function ServicesIndex() {
	const store = useSessionStore();
	
	const [loading, setLoading] = useState(false);
	const [search, setSearch] = useState("");
	const [clients, setClients] = useState([]);
	const [clientId, setClientId] = useState(0);
	const [services, setServices] = useState(null);
	const [mrcTotal, setMRCTotal] = useState(null);
	const [pagination, setPagination] = useState({
		PageIndex: 0,
		PageCount: 25,
		Total: 0
	});

	async function getServices(clientId, pageIndex, pageCount) {
		setLoading(true);
		const results = await store.ChannelPartnerService.GetServices(search, clientId, pageIndex, pageCount);
		
		if(results && results.Success) {
			setServices(results.Data);
			setMRCTotal(results.SumTotal);
			setPagination({
				PageCount: pageCount,
				PageIndex: pageIndex,
				Total: results.Total
			});
			setLoading(false);
		}
	}

	async function getClients() {
		const results = await store.ChannelPartnerService.GetClients();

		if(results && results.Success) {
			setClients(results.Data);
		}
	}

	useEffect(() => {
		getClients();
		getServices(0, 0, 25);
	}, []);

	function handlePageChanged(pageIndex, pageCount) {
		getServices(clientId, pageIndex, pageCount);
	}

	function handleClientChanged(value) {
		setClientId(value);
		getServices(value, 0, pagination.PageCount);
	}
	
	return <Page
				icon={ faWrench } 
				title="Services"
			>
				<Panel title="Viewing Active Services">
					<Row className="ps-3">
						<Col xs={4} className="py-3">
							<Form.Select 
								disabled={ loading || clients.length === 0 } 
								onChange={ e => handleClientChanged(e.target.value) }
								value={ clientId }>
								<option value="0"> - Select Client - </option>
								{
									clients.map(s => <option value={ s.Id }>{ s.Name }</option>)
								}
							</Form.Select>
						</Col>
						<Col>
							<Search 
								disabled={ loading }
								onSearch={ () => getServices(clientId, 0, pagination.PageCount) }
								search={ search }
								setSearch={ setSearch }
							/>
						</Col>
					</Row>					
					<Row>
						<Col>
							<Table className="mb-0">
								<thead>
									<tr>
										<th>Client</th>
										<th>ID</th>
										<th>Service</th>
										<th>Site Name</th>
										<th className="text-center">MRC</th>
										<th>Live Date</th>
										<th className="text-center">Contract Term</th>
										<th></th>
									</tr>
								</thead>
								<tbody>
									{
										!loading && services && services.map(s => {
											return 	<tr>
														<td 
															style={{ cursor: "pointer" }} 
															className="text-primary"
															onClick={ e => handleClientChanged(s.ClientId) }
														>
															{ s.Client }
														</td>
														<td>TC-{ s.ClientServiceID }</td>
														<td>{ s.Service }</td>
														<td>{ s.SiteName }</td>
														<td className="text-center">
															{ currency(s.MRC, { separator: " ", symbol: "R" }).format() }
														</td>
														<td>{ DateTime.fromISO(s.LiveDate).toFormat("dd MMMM yyyy") }</td>
														<td className="text-center">
															{ s.ContractTerm } <br /> 
															<Badge bg={ s.MonthsRemaining > 3 ? "secondary" : "danger" }>
																{ s.MonthsRemaining } remaining
															</Badge>
														</td>
														<td><ServiceActions service={ s } /></td>
													</tr>
										})
									}
									{
										!loading && mrcTotal !== null &&
										<tr style={{ borderTopWidth: "3px" }}>
											<td colSpan="4" className="text-end">
												MRC Total
											</td>
											<td className="text-center">
												<b>{ currency(mrcTotal, { separator: " ", symbol: "R" }).format() }</b>
											</td>
											<td colSpan="3"></td>
										</tr>
									}
								</tbody>
							</Table>
							{
								!loading &&
								<Pagination 
									filter={ pagination } 
									onPageChanged={ handlePageChanged } 
									totalResults={ services ? services.length : 0 }
								/>
							}
							<Spinner show={ loading } />
						</Col>
					</Row>
				</Panel>
			</Page>
}