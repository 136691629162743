import { Button, Modal, Table } from "react-bootstrap";
import { useSessionStore } from "../../../Stores/SessionStore";
import { useEffect, useState } from "react";
import Spinner from "../../Common/Spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRefresh } from "@fortawesome/free-solid-svg-icons";
import { saveAs } from "file-saver";

function DocumentRow({document}) {
	const store = useSessionStore();
	const [downloading, setDownloading] = useState(false);

	async function downloadFile() {
		setDownloading(true);
		let result = await store.ChannelPartnerService.GetDocument(document.FileId);

		if(result && result.Success) {
			const byteString = window.atob(result.Data.FileContents);
			const arrayBuffer = new ArrayBuffer(byteString.length);
			const int8Array = new Uint8Array(arrayBuffer);
			for (let i = 0; i < byteString.length; i++) {
				int8Array[i] = byteString.charCodeAt(i);
			}

			var blob = new Blob([int8Array], {type: result.Data.MimeType});
			saveAs(blob, result.Data.FileName);
			setDownloading(false);
		}
	}

	return <tr>
				<td>{ document.FileName }</td>
				<td className="text-center" style={{ cursor: "pointer" }}>
					{
						!downloading &&
						<span onClick={ downloadFile } className="text-primary" style={{ textDecoration: "none" }}>Download</span>
					}
					{
						downloading &&
						<FontAwesomeIcon icon={ faRefresh } spin />
					}
				</td>
			</tr>
}

export default function DocumentsModal({ show, clientServiceId, onClose }) {
	const store = useSessionStore();
	const [documents, setDocuments] = useState(null);

	async function getDocuments() {
		setDocuments(null);
		const result = await store.ChannelPartnerService.GetLinkedDocuments(clientServiceId);

		if(result && result.Success) {
			setDocuments(result.Data);
		}
	}

	useEffect(() => {
		if(show) {
			getDocuments();
		}
	}, [show])

	return 	<Modal size="lg" show={show}>
				<Modal.Header>
					<h4>Documents</h4>
				</Modal.Header>
				<Modal.Body>
					{
						documents &&
						<Table className="mb-0">
							<thead>
								<tr>
									<th>Filename</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								{
									documents.map(d => { return <DocumentRow document={d} /> })
								}
							</tbody>
						</Table>
					}
					<Spinner show={ !documents } />
				</Modal.Body>
				<Modal.Footer>
					<Button onClick={ onClose }>Close</Button>
				</Modal.Footer>
			</Modal>
}